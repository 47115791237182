/*
 * Customization Variables
 */

$accent: #0000FF;
$image-size: 120px;

/*
 * Build Variables, don't touch
 */

$max-width: 800px;
$med-width: 600px;
$small-width: 480px;
